@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  color: #646464 !important;
}

h1 {
  font-family: 'Space Mono', monospace !important;
  color: #FF8783;
  font-size: 2.5rem;
}

.container1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  font-size: 0.925rem;
  font-weight: 600 !important;
}


.bx {
  font-size: 2rem !important;
  margin-top: 0.5rem;
}

a .bx {
  color: #646464;
}

a:hover .bx {
  color: #FF8783;
}

.about_title {
  font-weight: bold;
}

.projects_title {
  font-weight: bold;
}
.container_about {
  min-height: 100vh;
}

.container_projects {
  min-height: 100vh;
}

.btn-outline-secondary {
  border-color: #646464;
  color: #646464;
}

.btn-outline-secondary:hover {
  border-color: #646464;
  background-color: #646464;
}

.tech {
  font-size: 0.75rem;
  font-weight: 400;
  background-color: #FF8783;
  padding: 0.4rem;
  border-radius: 5px;
  color: #fff;
}
.about_sub {
  font-size: 1.25rem;
  font-family: 'Space Mono', monospace !important;
  font-weight: bold;
}

.about_text {
  font-weight: 500;
  font-size: 0.9rem;
}
.about_main_text {
  font-size: 0.85rem;
  margin-top: 0.25rem;
}
.about_text span {
  color: #FF8783;
  margin-left: 5rem;
  font-size: 0.650rem;
}

.italic {
  font-style: italic;
  font-size: 0.75rem;
  margin-top: -1rem;
}

ul {
  margin-top: 0.75rem;
}
li {
  list-style: none;
  font-size: 0.75rem;
}

li:before {
  content: "·";
  font-size: 1rem;
  vertical-align: middle;
}

.skills {
  font-weight: 600;
  color: #323232;
}

.skills span {
  font-style: italic;
  font-weight: 400;
  color: #646464;
}

.certifications a {
  text-decoration: none;
  font-weight: 500;
  color: #323232;
}

.certifications a:hover {
  color: #FF8783;
}

.certifications .bx {
  font-size: 0.865rem!important;
}

.courses li span {
  font-style: italic;
  margin-left: 1.5rem;
  font-size: 0.65rem;
  color: #FF8783;
}
@media (min-width: 1200px) {
  .container1 {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 970px;
    min-height: 100vh;
  }
}

.heart {
  font-size: 1rem!important;
  color: #FF8783;
}
@media (min-width: 1200px) {
  .container1 {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 970px;
    min-height: 100vh;
  }
}

@media (max-width: 1024px) {
  .container1 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

  }

  .about_text span {
    display: none;
  }
}